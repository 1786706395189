import allSelectors from "./index"

const selectors = {}

selectors.shoppingCart = (state) => state.shoppingCart.cart

selectors.shops = (state) => {
  return selectors.shoppingCart(state).shops.filter(shop => shop.items.length > 0)
}

selectors.shopIds = (state) => {
  return selectors.shops(state).map(shop => shop.id)
}

selectors.items = (state) => {
  return selectors.shops(state).reduce((accum, shop) => [...accum, ...shop.items], [])
}

selectors.appliedCouponCode = (state) => {
  return selectors.shoppingCart(state).couponCode
}

selectors.pricing = (state) => {
  const { discount} = allSelectors.product.info(state)
  const deliveryCost = allSelectors.deliveries.deliveryCost(state)
  const shoppingCart = selectors.shoppingCart(state)

  const itemsTotal = shoppingCart.itemsTotal

  //TODO - discount

  const discountValue = discount ? itemsTotal * (Math.abs(discount) / 100) : 0
  const itemsDiscounted = itemsTotal - discountValue
  const final = parseFloat((itemsDiscounted + deliveryCost).toFixed(2))
  return {itemsTotal, final, discountValue, itemsDiscounted}
};

export default selectors;
